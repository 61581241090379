import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from '../../../components/ui/wrapper'
import Heading from '../../../components/ui/heading'
import Text from '../../../components/ui/text'
import VideoButton from '../../../components/ui/video-button'
import ModalVideo from '../../../components/ui/modal-video'
import AppointmentForm from '../../../components/forms/appointment-form'
import {
    HeroWrapper,
    HeroTextWrap,
    HeroButton,
    AppointmentWrap,
    AppointmentTitleWrap,
    AppointmentFormWrap
} from './hero-area.style'

const Hero = ({ subtitleStyle, titleStyle }) => {

    const heroData = useStaticQuery(graphql`
    query HeroQuery {
        indexAppointmentJson(id: {eq: "appointment-hero-content"}) {
          id
          title
          subtitle
          video
          buttonText
          bgImage {
            childImageSharp {
              fluid(maxWidth: 1920, maxHeight: 771, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
                presentationHeight
              }
            }
          }
        }
        homeBanner : allAirtable(
                filter: {
                  table: { eq: "SiteDetails" }
                  data: { Id: { eq: "homeBanner" } }
                }
              ) {
                edges {
                  node {
                    data {
                      Description
                      Subtext
                      Button
                      Link
                      Img
                    }
                  }
                }
           }    
      } 
    `);




    const homeBanner =  heroData.homeBanner.edges[0].node.data;


    const [videoOpen, setVideoOpen] = useState(false);

    const data = heroData.indexAppointmentJson;


    const modalVideoOpen = () => {
        setVideoOpen(true)
    }

    const modalVideoClose = () => {
        setVideoOpen(false)
    }

    return (
        <Fragment>
            <HeroWrapper
                tag="section"
                style={{backgroundImage: 'url('+homeBanner.Img+')', backgroundSize:'cover'}}
            >
                <Container style={{background:'rgba(18,176,119, .95)', padding:'0', maxWidth:'100%'}}>
                    <Row alignitems="center" style={{maxWidth : '1200px', margin:'0 auto'}}>
                        <Col md={6}>
                            <HeroTextWrap>
                                <Heading {...titleStyle}>{homeBanner.Description}</Heading>
                                <Heading {...subtitleStyle}>{homeBanner.Subtext}</Heading>                               
                            </HeroTextWrap>
                        </Col>
                        <Col lg={5} md={6} className="mx-auto">
                            <AppointmentWrap>
                                <AppointmentTitleWrap>
                                    <Heading as="h5" mb="10px">Exclusive travel info!</Heading>
                                    <Text>1000s are saving on travel planning. Savings directly to your inbox.</Text>
                                </AppointmentTitleWrap>
                                <AppointmentFormWrap>
                                    <AppointmentForm />
                                </AppointmentFormWrap>
                            </AppointmentWrap>
                        </Col>
                    </Row>
                </Container>
            </HeroWrapper>
            <ModalVideo
                channel='youtube'
                videoId='L61p2uyiMSo'
                isOpen={videoOpen}
                onClose={modalVideoClose}
            />
        </Fragment>
    )
}

Hero.propTypes = {
    subtitleStyle: PropTypes.object,
    titleStyle: PropTypes.object
}

Hero.defaultProps = {
    subtitleStyle: {
        as: 'h6',
        color: '#fff',
    },
    titleStyle: {
        as: 'h1',
        color: '#fff',
        fontweight: 500,
        mb: '30px',
        lineHeight : '60px'
    }
}

export default Hero;

//  <HeroButton>
//     <VideoButton
//         onClick={modalVideoOpen}
//         text="How we work"
//     />
// </HeroButton>